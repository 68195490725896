<template lang="pug">
article.guarantee
  .guarantee__cont.cont
    h1.guarantee__caption.caption Гарантия
    p.guarantee__text.guarantee__text_big.text-big.
      Мы производим мебель и комплектующие c соблюдением качества и безопасности. Заботясь о своих покупателях,
      мы внимательно относимся к качеству и тщательно проверяем комплектность перед отправкой.
    p.guarantee__text.guarantee__text_big.text-big.
      При этом мы всегда несем ответственность за качество и гарантийное обслуживание товара в соответствии с
      законодательством РФ.
    .guarantee__box
      h3.guarantee__headline.headline Гарантийный срок эксплуатации мебели ChiеdoHome со дня изготовления — 12 месяцев
      span.guarantee__note Если иное не предусмотрено договором
    h2.guarantee__title.title Гарантийные обязательства
    p.guarantee__text.text-big.
      Гарантийный срок на товар составляет 12 месяцев с даты получения товара Заказчиком, если иное не указано в
      договоре на конкретную партию товара.
    p.guarantee__text.text-big Гарантийные обязательства распространяются на дефекты производственного характера.
    p.guarantee__text.text-big
      | Гарантийные обязательства не распространяются на дефекты товара, возникшие в связи с нарушением
      |
      a.guarantee__link.text-big(href="#") правил эксплуатации
      | , размещенных на сайте chiedocover.ru, механическим повреждением товара.
    p.guarantee__text.text-big.
      В случае возникновения (обнаружения) дефекта товара, если будет установлено, что дефект носит производственный
      характер, Заказчик вправе потребовать от Поставщика замены товара, либо безвозмездного устранения данного
      дефекта, либо соразмерного уменьшения цены товара.
    h2.guarantee__title.title Порядок предъявления претензии по качеству
    p.guarantee__text.text-big.
      Претензия о возникновении (обнаружении) недостатка товара оформляется Заказчиком письменно и направляется
      Поставщику с приложением копии договора поставки, акта приёмки товара, фото/видео-изображений дефекта товара
      и иных документов, подтверждающих наличие и причину возникновения дефекта.
    p.guarantee__text.text-big.
      Поставщик в течение 10 дней с даты получения претензии обязан направить в адрес Заказчика мотивированный ответ
      с принятым по результатам рассмотрения претензии решением.
    p.guarantee__text.text-big.
      В случае принятия Сторонами решения о ремонте/замене товара, ремонт/замена товара производится в течение 14
      рабочих дней. В случае, если для осуществления ремонта/замены товара в указанный срок отсутствует какая-либо
      возможность, Стороны принимают решение о продлении срока ремонта/замены, либо приходят к иному оглашению о
      порядке исполнения гарантийных обязательств.
    p.guarantee__text.text-big.
      В случае изменения в ходе производства товара по инициативе Заказчика стандартных характеристик товара,
      Поставщик не несёт ответственности за возникновение дефектов, связанных с таким изменением, а гарантийное
      обслуживание не производится.
    p.guarantee__text.text-big.
      В случае приобретения товара разными партиями, Поставщик не гарантирует полного соответствия цвета, фактуры,
      размеров и т.д. комплектующих товара.
</template>

<script>
import '@/assets/styles/components/guarantee.sass'

export default {
  name: 'Guarantee'
}
</script>
