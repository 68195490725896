<template lang="pug">
head-meta(title-page="Гарантия на мебель из интернет магазина ChiedoHome", description="Условия гарантии на мебель в интернет магазине chiedohome. Узнайте больше об условиях, сроках, обязательствах")
guarantee
</template>

<script>
import Guarantee from '../components/guarantee/Guarantee'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  name: 'GuaranteePage',
  components: { HeadMeta, Guarantee }
}
</script>
